/deep/ {
  .el-upload {
    width: 100%;
    min-height: 68px;
    border: 1px dashed $bottle-blue;
    border-radius: 6px;
    background-color: $white;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    line-height: 68px;
  }

  .el-upload__tip {
    line-height: 1.5;
  }

  .upload-demo {
    img,
    .button-upload {
      display: inline-block;
    }

    .button-upload {
      font-size: 12px;
      color: rgb(98, 109, 113);
      line-height: 2.249;
    }
  }
}

.button-upload {
  @include rtl-sass-prop(margin-left, margin-right, 15px);
}
