/deep/ .el-dialog__body {
  padding: 45px 55px 35px;
  text-align: center;
  word-break: normal;

  p {
    margin-top: 30px;
    margin-bottom: 50px;
    font-size: 15px;
    color: rgb(98, 109, 113);
    line-height: 2;
  }

  .btn-red {
    width: 100%;
    max-width: 206px !important;
  }
}

@media (max-width: 550px) {
  /deep/ .el-dialog__body {
    padding: 35px;

    .btn-red {
      max-width: 45% !important;
    }
  }
}

@media (max-width: 500px) {
  /deep/ .el-dialog__body {
    padding: 20px;
  }
}
