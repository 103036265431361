#rebateWithdraw {
  .withDraw-box {
    border-radius: 8px;
    background-color: $white;
    padding: 20px 40px 40px;

    .withDraw-form {
      max-width: 904px;
      border-radius: 8px;
      background-color: $light-gray;
      padding: 40px;
      display: grid;
      grid-template-columns: 35px calc(100% - 70px);
      column-gap: 35px;
      margin: 0 auto 20px;

      .submit-btn-box {
        text-align: start;
        margin-top: 20px;

        /deep/ .el-button {
          width: 45%;
        }
      }

      /deep/ {
        .form-list-inner {
          margin-bottom: 10px;

          p {
            width: 100%;
            font-size: 15px;
            color: $blue;
            line-height: 1.799;
            text-transform: uppercase;
            margin-bottom: 20px;
            @include rtl-sass-value(text-align, left, right);

            &.alert {
              margin: 0;
              font-size: 12px;
              color: $red;
            }
          }

          > ul {
            display: grid;
            grid-template-columns: 45% 45%;
            grid-column-gap: 10%;

            > li {
              .el-form-item__content {
                line-height: 20px;
              }

              .el-form-item__error {
                padding-top: 10px;
              }

              .el-radio-group {
                width: 100%;
              }
            }
          }

          .form-list-three-columns {
            gap: 10px;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          }

          &.to > ul {
            grid-template-columns: 100%;
          }

          .alert-info {
            font-size: 12px;
            color: red;
            margin-top: 10px;
          }

          .sys-info {
            border-style: solid;
            border-width: 1px;
            border-color: rgba(39, 5, 109, 0.1);
            border-radius: 6px;
            background-color: $white;
            padding: 10px;
            font-size: 15px;
            color: #626d71;
            line-height: 1.333;
          }
        }

        /* radio的样式 */
      }

      /deep/ {
        .mobile-title {
          background-color: $blue;
          color: white;
          width: 150px;
          margin-bottom: 20px;
          text-align: center;
          line-height: 2.2;
          margin: 0 auto;
          margin-bottom: 20px;
        }

        .el-tabs {
          margin-top: 40px;
          position: relative;
        }

        .el-tabs__header {
          margin-bottom: 0;
          width: 100%;
        }

        .el-tabs__nav {
          word-break: break-all;
          white-space: break-spaces;
        }

        .el-tabs__nav-scroll,
        .el-tabs__nav-wrap {
          overflow: unset;
        }

        .el-tabs__item {
          border-style: solid;
          border-width: 1px;
          border-color: rgba(39, 5, 109, 0.1);
          background-color: rgb(184, 205, 234);
          width: calc((100% - (0.5% * 3)) / 4);
          height: 60px;
          font-size: 15px;
          color: $blue;
          position: absolute;
          bottom: -60px;
          border-radius: 4px 4px 0 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-content: center;
          line-height: 1.5;
          transition: height 0.5s;
          word-break: initial;
          display: -webkit-flex; /* Safari */
          align-items: center; /* Safari */

          &:not(:last-child) {
            margin-right: 0.5%;
          }

          &:first-child {
            left: 0;
          }

          &:nth-child(2) {
            left: calc(((100% - (0.5% * 3)) / 4) + 0.5%);
          }

          &:nth-child(3) {
            left: calc((((100% - (0.5% * 3)) / 4) * 2) + (0.5% * 2));
          }

          &:nth-child(4) {
            left: calc((((100% - (0.5% * 3)) / 4) * 3) + (0.5% * 3));
          }

          &:nth-child(5) {
            left: calc((((100% - (0.5% * 3)) / 4) * 4) + (0.5% * 4));
          }

          &.is-active {
            background-color: $blue;
            box-shadow: 2.007px 2.229px 7.56px 1.44px $gray-blue;
            color: $white;
            height: 102px;
          }
        }

        .el-tabs__nav-wrap.is-scrollable {
          padding: 0;
        }

        .el-form-item__content {
          line-height: 0 !important;
        }

        .el-tabs__content {
          border-style: solid;
          border-width: 1px;
          border-color: rgba(39, 5, 109, 0.1);
          background-color: $white;
          width: 100%;
          padding: 90px 20px 20px;

          .el-tab-pane {
            display: grid;
            grid-template-columns: repeat(auto-fill, 130px);
            column-gap: 10px;
            row-gap: 15px;
          }

          .item {
            text-align: center;
            display: grid;
            grid-template-columns: 30px 96px;
            cursor: pointer;

            .left {
              align-self: center;
              margin-bottom: 20px;
              content: url(~@/assets/images/rebateWithdraw/radio01.png);
            }

            .right {
              .logoLg {
                width: 120px !important;
              }

              .bottom {
                font-size: 12px;
                text-transform: none;
                color: red;
                text-align: left;
                min-width: 500px;
                margin-left: 20px;
              }

              .logo {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 0 0;
                width: 96px;
                height: 56px;
                border-width: 2px;
                border-color: $blue;
                border-style: solid;
                border-radius: 4px;

                .img {
                  vertical-align: baseline;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: 0 0;
                  align-self: end;
                  margin: 2px auto 0;
                }

                // Credit Card
                &.img1 .img {
                  width: 85px;
                  height: 100px;
                  background-image: url('~@/assets/images/channel/creditcard.png');
                }

                // Bank Transfer
                &.img2 .img {
                  width: 48px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/international.png');
                }

                // UnionPay
                &.img4 .img {
                  width: 70px;
                  height: 43px;
                  background-image: url('~@/assets/images/channel/unionpay_china.png');
                }

                // FasaPay
                // Sticpay
                &.img7 .img,
                &.img32 .img,
                &.img38 .img {
                  width: 80px;
                  height: 20px;
                  margin-top: 20px;
                }

                // FasaPay
                &.img7 .img {
                  background-image: url('~@/assets/images/channel/fasapay.png');
                }

                // Skrill
                &.img31 .img {
                  width: 80px;
                  height: 20px;
                  margin-top: 16px;
                  margin-left: 16px;
                  background-image: url('~@/assets/images/channel/skrill.png');
                }

                // Neteller
                &.img32 .img {
                  background-image: url('~@/assets/images/channel/neteller.png');
                }

                // Sticpay
                &.img38 .img {
                  background-image: url('~@/assets/images/channel/sticPay.png');
                }

                // Cryptocurrency-BTC
                &.img34 .img,
                &.img36 .img {
                  width: 48px;
                  height: 48px;
                  margin-top: 0;
                }

                &.img34 .img {
                  background-image: url('~@/assets/images/channel/bitcoin.png');
                }

                // Cryptocurrency-USDT
                &.img36 .img {
                  background-image: url('~@/assets/images/channel/USDT_1.png');
                }

                // Bitwallet
                &.img37 .img {
                  width: 50px;
                  height: 49px;
                  background-image: url('~@/assets/images/channel/bitwallet.png');
                }

                // 5: Thailand Bank Transfer
                // 6: Malaysia Bank Transfer
                // 8: Vietnam Bank Transfer
                // 24: India Bank Transfer
                // 35: South Korea Bank Transfer
                // 39: Indonesia Instant Bank Wire Transfer
                // 40: Philippine Instant Bank Transfer
                // 42: South Africa Instant Bank Transfer
                &.img5 .img,
                &.img6 .img,
                &.img8 .img,
                &.img24 .img,
                &.img35 .img,
                &.img39 .img,
                &.img40 .img,
                &.img42 .img {
                  width: 48px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/bank.png');
                }

                &.img50 .img, // Uganda
                &.img51 .img {
                  // Rwanda
                  width: 48px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/bank_southafrica.png');
                }

                &.img52 .img {
                  // Zambia
                  width: 48px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/bank_zambia.png');
                }

                &.img53 .img {
                  // Congo
                  width: 48px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/bank_congo.png');
                }

                &.img54 .img {
                  // Cameroon
                  width: 48px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/bank_cameroon.png');
                }

                &.img55 .img {
                  // Burundi
                  width: 48px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/bank_burundi.png');
                }

                &.img56 .img {
                  // Kenya
                  width: 48px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/bank_kenya.png');
                }

                &.img57 .img {
                  // Rwanda
                  width: 48px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/bank_ghana.png');
                }

                &.img58 .img {
                  // Tanzania
                  width: 48px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/bank_tanzania.png');
                }

                &.img60 .img {
                  // Canadian Local Bank Transfer
                  width: 110px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/interac.png');
                }

                &.img61 .img {
                  width: 66px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/perfect_money.png');
                }

                // 9: Nigeria Bank Transfer
                &.img9 .img {
                  width: 48px;
                  height: 48px;
                  background-image: url('~@/assets/images/channel/bank_nigeria.png');
                }
              }

              span {
                font-size: 12px;
                color: $blue;
                line-height: 1.5;
              }
            }

            &.active {
              .left {
                content: url(~@/assets/images/rebateWithdraw/radio03.png);
              }

              .right .logo {
                border-width: 2px;
                border-color: $bottle-blue;
                border-style: solid;
                border-radius: 4px;
                background-color: $blue;

                &.img1 .img {
                  background-position: 0 -50px;
                }

                &.img4 .img {
                  background-position: -70px 0;
                }

                &.img7 .img,
                &.img31 .img,
                &.img32 .img,
                &.img38 .img {
                  background-position: 0 -20px;
                }

                &.img37 .img {
                  background-position: -50px 0;
                }

                &.img2 .img,
                &.img5 .img,
                &.img6 .img,
                &.img8 .img,
                &.img9 .img,
                &.img24 .img,
                &.img34 .img,
                &.img35 .img,
                &.img36 .img,
                &.img39 .img,
                &.img40 .img,
                &.img42 .img,
                &.img50 .img,
                &.img51 .img,
                &.img52 .img,
                &.img53 .img,
                &.img54 .img,
                &.img55 .img,
                &.img56 .img,
                &.img57 .img,
                &.img58 .img {
                  background-position: -48px 0;
                }

                &.img60 .img {
                  background-position: 0;
                }

                &.img61 .img {
                  background-position: -66px 0;
                }
              }
            }
          }
        }
      }
    }
  }

  /* 谷歌 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  /* 火狐 */
  input {
    -moz-appearance: textfield;
  }
}

/deep/ .el-steps {
  .el-step:nth-child(1) {
    flex-basis: 190px !important;
  }

  .el-step:nth-child(2) {
    flex-basis: calc(100% - 220px) !important;
  }
}

@media (max-width: 1024px) {
  #rebateWithdraw .withDraw-box .withDraw-form {
    grid-template-columns: 35px calc(100% - 50px);

    .submit-btn-box {
      margin-top: 30px;
      margin-bottom: 10px;

      /deep/ .el-button {
        width: 48%;
      }
    }

    /deep/.form-list-inner {
      > ul {
        grid-template-columns: 48% 48%;
        column-gap: 4%;
        row-gap: 5px;

        > li .el-form-item__label {
          font-size: 12px;
        }
      }
    }

    /deep/ {
      .el-tabs {
        margin-top: 0;
      }
      .el-tabs__header {
        margin-bottom: 85px;
      }

      .el-tabs__item {
        border-radius: 10px;
        width: 24%;

        &.is-active {
          background-color: $blue;
          box-shadow: 2.007px 2.229px 7.56px 1.44px $gray-blue;
          color: $white;
          height: 70px;
        }
      }
      .el-tabs__content {
        padding-top: 25px;
        border-radius: 5px;
      }
    }
  }
}

@media (max-width: 768px) {
  #rebateWithdraw .withDraw-box {
    padding: 10px 30px;
    .withDraw-form {
      padding: 20px;
      column-gap: 10px;
    }
  }
}

@media (max-width: 550px) {
  #rebateWithdraw .withDraw-box {
    padding: 10px;
    .withDraw-form {
      column-gap: 5px;
      padding: 15px;
      grid-template-columns: 35px calc(100% - 40px);

      .submit-btn-box /deep/ .el-button {
        width: 100%;
      }

      /deep/.form-list-inner {
        margin-bottom: 0;

        > ul {
          grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
          row-gap: 2px;
        }
      }

      /deep/ .mobile-title {
        margin-bottom: 10px;
      }

      /deep/ {
        .el-tabs__item {
          padding: 8px;
          height: 60px;
          &.is-active {
            height: 60px;
          }
        }

        .el-tabs__content {
          @include rtl-sass-prop(padding-left, padding-right, 10px);
          @include rtl-sass-prop(padding-right, padding-left, 0);
          .item {
            grid-template-columns: 30px 56px;
            .right {
              width: 100px;
              span {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
